import React from 'react';
import { graphql } from 'gatsby';
import { FaChevronRight } from 'react-icons/fa';
import { v4 } from 'uuid';
import LinkComponent from '../../components/LinkComponent';
import Layout from '../../components/Layout';
import Image from '../../components/Image';
import BackgroundImage from '../../components/BackgroundImage';
import { ImageOverlay } from '../../utils';
import useSiteMetadata from '../../components/SiteMetadata';
import MetaComponent from '../../components/MetaComponent';

export const ExperiencesPageTemplate = ({
  image,
  title,
  experiences,
  helmet,
}) => {
  const mappedExperiences = experiences.map((promotion) => (
    <div className='column is-4' key={v4()}>
      <LinkComponent linkObject={promotion.linkObject}>
        <Image
          imageInfo={{ image: promotion.image }}
          classes='property-roll-image'
          animationClass='hover-zoom'
        />
        <h4 className='title experiences-roll-title'>{promotion.title}</h4>
        <div>{promotion.subTitle}</div>
        {!!promotion.isCta ? (
          <div className='experiences-button'>
            Book now <FaChevronRight style={{ height: '13px' }} />
          </div>
        ) : null}
      </LinkComponent>
    </div>
  ));
  return (
    <div className='page-container container content'>
      {helmet || ''}
      <BackgroundImage classes={'page-header-image'} imageInfo={image.image}>
        <h1 className='title page-title'>
          <hr />
          {title}
        </h1>
        <ImageOverlay overlay={image.overlay} />
      </BackgroundImage>

      <div className='container content'>
        <div className='columns is-variable is-6 is-multiline'>
          {mappedExperiences}
        </div>
      </div>
    </div>
  );
};

const ExperiencesPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const { title, siteUrl } = useSiteMetadata();
  return (
    <Layout>
      <ExperiencesPageTemplate
        location={location}
        image={frontmatter.heroImageObject}
        title={frontmatter.experiencesTitle}
        experiences={frontmatter.experiences}
        helmet={
          <MetaComponent
            title={`${frontmatter.seoObject.metaTitle}`}
            canonical={`${siteUrl}${location.pathname}`}
            siteName={`${title}`}
            description={`${frontmatter.seoObject.metaDescription}`}
            link={`${siteUrl}${location.pathname}`}
            image={`${frontmatter.heroImageObject.image}`}
          />
        }
      />
    </Layout>
  );
};

export default ExperiencesPage;

export const pageQuery = graphql`
  query ExperiencesPageQueryJP {
    markdownRemark(
      frontmatter: { templateKey: { eq: "experiences-page-jp" } }
    ) {
      frontmatter {
        experiencesTitle
        seoObject {
          metaTitle
          metaDescription
        }
        heroImageObject {
          overlay
          image
        }
        experiences {
          image
          isCta
          title
          subTitle
          linkObject {
            link
            isExternal
          }
        }
      }
    }
  }
`;
